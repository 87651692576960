<template>
  <!-- Popup -->
  <Popup ref="popup" />

  <div class="col-8 align-items-center">
    <div class="row align-items-center">
      <h4>
        Balance: {{ Math.round(stakersNftcBalance * 100000) / 100000 }}
        {{ tokenName["nftc"] }}
        &nbsp;
        <i title="Refresh values" @click="load()" class="ri-refresh-line"></i>
      </h4>
      <br />
      <h4>Staking power: {{ stakersPower / 10000 }}⚡</h4>
      <br />
      <h6>Total power staked: {{ totalStakedPwr / 10000 }}⚡</h6>
      <h6>
        Total Emission: ~{{
          Math.round(emissionPerDayE18 * earningsTime * 100) / 100
        }}
        {{ tokenName["nftc"] }} / {{ hoursInWords[earningsTime] }}
      </h6>
      <br />
      <div
        class="settings-menu"
        v-if="emissionPerDayE18 && totalStakedPwr && stakersPower"
      >
        <button
          class="btn btn-primary"
          type="button"
          id="collection-filter"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="font-size: 14px"
        >
          Earning ~{{
            Math.round(
              (emissionPerDayE18 / totalStakedPwr) *
                stakersPower *
                earningsTime *
                100000
            ) / 100000
          }}
          {{ tokenName["nftc"] }} /
          {{ hoursInWords[earningsTime] }}
        </button>
        <ul class="dropdown-menu" aria-labelledby="earnings-time">
          <li v-for="_hours in [1, 24, 168, 720, 8760]" :key="_hours">
            <a
              class="dropdown-item"
              @click="earningsTime = _hours"
              :class="earningsTime === _hours ? 'current' : ''"
            >
              Earning ~{{
                Math.round(
                  (emissionPerDayE18 / totalStakedPwr) *
                    stakersPower *
                    _hours *
                    100000
                ) / 100000
              }}
              {{ tokenName["nftc"] }} / {{ hoursInWords[_hours] }}
            </a>
          </li>
        </ul>
      </div>
      <div class="settings-menu">
        <button
          @click="harvest"
          class="btn btn-primary harvest-button"
          :disabled="harvestDisabled"
          style="font-size: 14px"
        >
          Harvest {{ nftcToHarvestE18 }} {{ tokenName["nftc"] }}
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import { ethers } from "ethers";
import NftStake from "../../artifacts/contracts/NftStake.sol/NftStake.json";
import SimpleToken from "../../artifacts/contracts/SimpleToken.sol/SimpleToken.json";

import Popup from "./Popup.vue";

export default {
  components: { Popup },

  emits: ["refreshEvent"],

  data() {
    return {
      nftcTokenContract: null,
      tokenName: window.tokenName,

      nftContract: null,
      stakingContract: null,

      provider: null,
      signer: null,

      emissionPerDayE18: null,

      walletAddress: null,
      stakersPower: null,
      totalStakedPwr: null,
      earningsTime: 168,
      hoursInWords: {
        1: "hour",
        24: "day",
        168: "week",
        720: "month",
        8760: "year",
      },

      nftcToHarvestE18: null,
      harvestDisabled: true,
      stakersNftcBalance: 0,
    };
  },

  methods: {
    showPopup(_title, _text, _timeOut) {
      this.$refs.popup.show(_title, _text, _timeOut);
    },

    async handleTx(_msg, _fx) {
      this.showPopup(_msg, "Please confirm the transaction", 0);

      try {
        let tx = await _fx;
        this.showPopup(_msg, "Waiting for transaction to finish", 0);
        await tx.wait();
        this.showPopup(_msg, "was successful", 1000);
      } catch {
        this.showPopup(_msg, "finished", 1000);
      }
    },

    harvest: async function () {
      const _msg = "Harvesting";
      await this.handleTx(
        _msg,
        this.stakingContract.harvest({
          value: ethers.utils.parseUnits("5", 15),
        })
      );
      this.load();
    },

    approve: async function () {
      const _msg = "Approving NFTs for staking";
      await this.handleTx(
        _msg,
        this.nftContract.setApprovalForAll(window.address["staking"], true)
      );

      this.$emit("refreshEvent");
    },

    loadNftcBalance: function () {
      this.nftcTokenContract.balanceOf(this.walletAddress).then((balance) => {
        this.stakersNftcBalance = ethers.utils.formatEther(balance);
      });
    },

    load: function () {
      // this.stakingContract.allocatedPower().then((_power) => {
      //   this.totalStakedPwr = _power;
      // });

      // this.stakingContract.getEarningPerHour().then((amount) => {
      //   this.emissionPerDayE18 =
      //     Math.round(
      //       ethers.utils.formatEther(amount) * (1 - window.DevCut) * 100000
      //     ) / 100000;
      // });

      this.signer.getAddress().then((userAddress) => {
        this.walletAddress = userAddress;

        this.stakingContract.getStakingStats(userAddress).then((_res) => {
          this.totalStakedPwr = _res[0];

          this.emissionPerDayE18 =
            Math.round(
              ethers.utils.formatEther(_res[1]) * (1 - window.DevCut) * 100000
            ) / 100000;

          const nftcEarnedSoFar = _res[2];
          console.log(ethers.utils.formatEther(nftcEarnedSoFar));

          this.nftcToHarvestE18 =
            Math.round(ethers.utils.formatEther(nftcEarnedSoFar) * 100000) /
            100000;
          if (this.nftcToHarvestE18 > window.minHarvest) {
            this.harvestDisabled = false;
          }

          this.stakersPower = _res[3];
        });

        // this.stakingContract
        //   .earningsWoDev(userAddress)
        //   .then((nftcEarnedSoFar) => {
        //     console.log(nftcEarnedSoFar);
        //     console.log(ethers.utils.formatEther(nftcEarnedSoFar));

        //     this.nftcToHarvestE18 =
        //       Math.round(ethers.utils.formatEther(nftcEarnedSoFar) * 100000) /
        //       100000;
        //     if (this.nftcToHarvestE18 > window.minHarvest) {
        //       this.harvestDisabled = false;
        //     }
        //   });

        // this.stakingContract.stakers(userAddress).then((staker) => {
        //   this.stakersPower = staker.power;
        // });

        this.loadNftcBalance();
      });
    },
  },

  mounted: function () {
    this.provider = new ethers.providers.Web3Provider(window.ethereum, "any");

    this.provider
      .send("eth_requestAccounts", [])
      .then(() => {
        this.signer = this.provider.getSigner();

        this.nftcTokenContract = new ethers.Contract(
          window.address["nftc"],
          SimpleToken.abi,
          this.provider.getSigner()
        );

        this.stakingContract = new ethers.Contract(
          window.address["staking"],
          NftStake.abi,
          this.provider.getSigner()
        );
      })
      .then(() => {
        this.load();
      });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;
}
</style>
