<template>
  <div class="content-body">
    <div class="container text-start">
      <div class="row">
        <div class="col-xxl-6">
          <div
            class="promotion d-flex justify-content-between align-items-center"
          >
            <div class="promotion-detail">
              <h3 class="text-white mb-3">Shorai</h3>
              <p>
                In a dystopian age, threatened by the dark Senshi, the Shorai
                are the only hope of restoring peace. Can you help them find the
                Maho-Balls and fight the Senshi?
              </p>
              <a
                href="https://oasis.cash/collection/0xf928B82061bc531e3B24B8CF3736724e35988b7a"
                target="_blank"
                class="btn btn-secondary me-3"
                >Buy on Oasis</a
              >
            </div>
          </div>
        </div>
        <div class="col-xxl-6">
          <div class="card top-bid">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <img
                    src="/images/avatar/shorai.jpg"
                    class="img-fluid rounded"
                    alt="..."
                  />
                </div>
                <NftTop @refreshEvent="reloadChildren" ref="nftTop" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Nft @refreshEvent="reloadChildren" ref="nft" :currColl="currColl" />
  </div>
</template>

<script>
import Nft from "@/components/Nft.vue";
import NftTop from "@/components/NftTop.vue";

// Mainnet
import initialStakes from "../bchMainnet/shorai-staked.json";
import power from "../bchMainnet/shorai-power.json";
import minpower from "../bchMainnet/shorai-minpower.json";

// // Testnet
// import initialStakes from "../bchTestnet/shoraiTest-staked.json";
// import power from "../bchTestnet/shoraiTest-power.json";
// import minpower from "../bchMainnet/shorai-minpower.json";

export default {
  name: "App",
  components: { Nft, NftTop },

  data() {
    return {
      currColl: {
        name: "shorai",
        fullName: "Shorai",
        nftCA: window.address["shorai"],
        power: power,
        minpower: minpower,
        initialStakes: initialStakes,
        pictureLink:
          "https://nftraritycash.github.io/nftassets/shorai-optimized/",
      },

      nftMarketPlace: window.nftMarketPlace,
    };
  },
  methods: {
    reloadChildren: function () {
      this.$refs.nftTop.load();
      this.$refs.nft.load();
    },
  },
};
</script>
